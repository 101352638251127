.extra-info-widget {
    display: flex;
    justify-content: center; 
    align-items: center; 
    /* margin: 10px; */
}

.extra-info-widget.danger {
    /* color: red; */
    /* other styles */
}

.depth {
    /* color: blue; */
    flex: 1;
    border: solid;
    padding: 10px;
    margin: 5px;
}

.time {
    flex: 1;
    border: solid;
    padding: 10px;
    margin: 5px;
}

.gradient-factor {
    color: green;
    flex: 1;
    border: solid;
    padding: 10px;
    margin: 5px;
}

.gradient-factor.danger {
    color: red;
    flex: 1;
    border: solid red;
    padding: 10px;
    margin: 5px;
}

.dive-max-gf {
    color: purple;
    flex: 1;
    border: solid;
    padding: 10px;
    margin: 5px;
}

.dive-max-gf.danger {
    color: red;
    flex: 1;
    border: solid red;
    padding: 10px;
    margin: 5px;
}