.vertical-slider {
    appearance: slider-vertical; /* For webkit browsers */
    width: 20px; /* Adjust the width as needed */
    height: 100%; /* Full height of the container */
    outline: none; /* Remove the outline */
    /* align-self: right; */
    /* Add other styles if necessary */
  }
  
  .slider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* Add other styles if necessary */
  }
  