.App {
  text-align: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.game-and-settings-area {
  display: flex;
  flex-direction:row;
  height: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start of the cross axis */
  gap: 10px; /* Adds space between flex items */
  /* border: solid; */
  /* background-color: lightcoral; */
}

.game-pane {
  flex: 5;
  height: 100%;
  width: 100%;
  /* border: dashed; */
  padding: 10px;
  /* border: solid red; */
  /* display: flex; */
  /* flex-direction:row; */
  /* height: flex; */
  /* justify-content: space-between; */
  /* align-items: flex-start; Align items to the start of the cross axis */
  /* gap: 10px; Adds space between flex items */
  /* background-color: lightcoral; */
  /* Optional: if you want to set a maximum width or use a percentage */
  /* min-height: 400; */
  /* background-color: lightblue; */
}

.live-plot, .plot {
  width: 100%; /* This will make them fill their flex container */
  height: 100%;
  display: flex;
  flex-direction: row;
  /* background-color: red; */
}

.settings-pane {
  flex: 1; /* Takes 1 part of the space */
  /* Max width to limit the width of settings pane */
  max-width: 300px; /* Adjust this as needed */
  margin: 10px;
  padding: 10px;
  background-color: lightgrey;
  /* border: solid red; */
  /* Make sure child elements like h3 and input are styled correctly */
}

/* Specific styles for elements inside settings-pane */
.settings-pane h3 {
  padding: 20px 10px 0px 10px;
  margin: 0;
}
.settings-pane input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}
.settings-pane ul {
  background-color: none;
  text-align: left;
  list-style-position: outside;
}

.game-header {
  display: flex;
  gap: 10px;
}

.extra-info-widget{
  flex: 5 1;
  /* border: solid red; */
}

.user-info {
  margin: 10px;
  padding: 10px;
  /* border: solid red; */
}

.radio {
  display: flex;
  align-items: center; 
  margin-bottom: 10px;
}